<script setup>
import { useMessageStore } from "@/stores/message";
import { useField, useForm } from "vee-validate";
import * as yup from "yup";
import { defineProps, ref, onMounted, watchEffect, nextTick } from "vue";
import { useGoTo } from "vuetify";
import { watch } from "vue";
import { useAuthStore } from "@/stores/auth";
import { getSocket } from "@/mixins/socket";

const props = defineProps({ chat: Object });
let chat = ref(props.chat);

const { handleSubmit } = useForm({
  validationSchema: yup.object({
    message: yup.string().required().min(1),
  }),
});

const goTo = useGoTo();
const useAuth = useAuthStore();
const useMessage = useMessageStore();

let messages = ref([]);
let message = useField("message");
let messageList = ref(null);

const user = useAuth.user;
console.log("user: ", user);
// Optionally, update currentId whenever chat changes
watchEffect(() => {
  chat.value = props.chat;
  if (messageList.value) toBottom();
});

console.log("MainChatComponent.vue", chat);

onMounted(() => {
  toBottom();
});

watch(
  chat,
  async (newChat, oldChat) => {
    // console.log("newChat: ", newChat, "\noldChat: ", oldChat);
    // Check if the ID has actually changed
    if (newChat !== oldChat) {
      try {
        // Call the function with the new ID
        messages.value = await useMessage.getMessages(newChat.id);
        await nextTick(() => {
          console.log("watch: ", messageList.value.$el);
          toBottom();
        });
      } catch (error) {
        console.error("Error fetching messages:", error);
      }
    }
  },
  { immediate: true }
); // immediate: true ensures the watcher runs immediately upon component mount

const toBottom = () => {
  if (messageList.value) {
    console.log("messageList: ", messageList.value);
    console.log("scrollTop: ", messageList.value.$el?.scrollTop);
    console.log("clientHeight: ", messageList.value.$el?.clientHeight);
    goTo(messageList.value.$el, {
      offset: 0,
    });
  }
};

messages.value = await useMessage.getMessages(props.chat.id);

const sendMessage = handleSubmit(async () => {
  let msg = await useMessage.createMessage(props.chat.id, message.value.value);
  console.log("message: ", msg, "chat: ", props.chat.id);
  message.value.value = "";
});

getSocket().on("receive_message", async (msg) => {
  console.log("receive_message: ", msg);
  if (msg.Participant.chatId === props.chat.id) {
    messages.value.push(msg);
    await nextTick(() => {
      toBottom();
    });
  }
});
</script>

<template>
  <v-container>
    <v-app-bar
      app
      color="green-lighten-5 border rounded-b-lg"
      elevation="0"
      dark
    >
      <v-app-bar-nav-icon @click="goTo('/chats')">
        <v-avatar>
          <v-img src="/assets/icons/back.svg"></v-img>
        </v-avatar>
      </v-app-bar-nav-icon>
      <v-app-bar-title>
        {{ chat?.name }}
      </v-app-bar-title>
    </v-app-bar>
    <v-cards
      v-for="message in messages"
      :key="message.id"
      class="d-flex flex-column my-2"
    >
      <v-card
        elevation="0"
        :class="{
          'text-body-2 border rounded-xl py-2 px-4 w-50': true,
          'border bg-green-lighten-4 align-self-end':
            user.id == message?.Participant?.userId,
        }"
      >
        {{ message.message }}
        <v-card-subtitle class="text-right text-caption text-grey-lighten-1">
          {{ new Date(message.createdAt).toLocaleString() }}
        </v-card-subtitle>
      </v-card>
    </v-cards>
    <v-card ref="messageList"></v-card>
    <v-footer height="72" app class="border rounded-t-lg">
      <form @submit.prevent="sendMessage" class="w-100">
        <v-text-field
          bg-color="grey-lighten-4"
          class="overflow-hidden"
          density="compact"
          rounded="pill"
          variant="solo-filled"
          flat
          hide-details
          v-model="message.value.value"
          :error-messages="message.errorMessage.value"
          placeholder="Type a message"
        >
          <template #prepend>
            <v-btn icon elevation="0" class="bg-transparent">
              <v-icon>mdi-emoticon-happy-outline</v-icon>
            </v-btn>
          </template>
          <template #append>
            <v-btn icon elevation="0" class="bg-transparent">
              <v-icon>mdi-paperclip</v-icon>
            </v-btn>
          </template>
          <template #append-inner v-if="message.value?.value?.length > 0">
            <v-btn
              type="submit"
              icon
              elevation="0"
              class="bg-transparent"
              @click="sendMessage"
            >
              <v-icon>mdi-send</v-icon>
            </v-btn>
          </template>
        </v-text-field>
      </form>
    </v-footer>
  </v-container>
</template>
