<script setup>
import MainAsideComponent from "@/components/MainAsideComponent.vue";
import MainChatComponent from "@/components/chats/MainChatComponent.vue";
import { ref } from "vue";
let chat = ref({});
let isOpenChat = ref(false);

const openChat = (c) => {
  chat.value = null;
  isOpenChat.value = false;
  if (c) {
    chat.value = c;
    isOpenChat.value = true;
  }
  // alert(isOpenChat.value);
  console.log("main component: ", c);
  console.log("main component: ", chat.value);
};
</script>

<template>
  <v-main>
    <MainAsideComponent @open-chat="openChat" />
    <MainChatComponent v-if="isOpenChat === true" :chat="chat" />
    <v-container v-else>
      <!-- create this container for empty chat or not selected chat -->
      <v-card class="mx-auto" elevation="0">
        <v-card-text>
          <v-row align="center" justify="center">
            <v-col cols="12" md="6">
              <v-img
                src="https://cdn.vuetifyjs.com/images/john.jpg"
                aspect-ratio="1"
              ></v-img>
              <v-card-title class="text-h5 font-weight-bold">
                Select a chat to start messaging
              </v-card-title>
              <v-card-subtitle class="text-body-1">
                Messages are private and end-to-end encrypted
              </v-card-subtitle>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-container>
  </v-main>
</template>
