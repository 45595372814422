<script setup>
import ChatItemComponent from "@/components/chats/ChatItemComponent.vue";
import { defineProps, ref, defineEmits } from "vue";
defineProps({ activator: String });
import { useChatStore } from "@/stores/chat";
import { useCountryStore } from "@/stores/country";
import { useUserStore } from "@/stores/user";
let emits = defineEmits(["update-chats"]);
let useChat = useChatStore();
let chats = await useChat.getChats();
let useUser = useUserStore();
let user = ref(null);
let step = ref(1);
let isNewGroup = ref(false);
let isNewPhoneNumber = ref(false);
let phoneNumber = ref("");
addEventListener("keydown", (e) => {
  if (e.key === "Escape") {
    step.value = 1;
    isNewGroup.value = false;
    isNewPhoneNumber.value = false;
    phoneNumber.value = "";
    countries = useCounty.getCountries;
    countryName.value = "";
    country = null;
    user.value = null;
  }
});

const addDigit = async (digit) => {
  // check for the coutry code max 3 digit
  if (phoneNumber.value?.split(" ")[1]?.length === 9) {
    user.value =
      (await useUser.getUser(phoneNumber.value.replace(" ", ""))) ?? null;
  }

  if (digit === "+" && phoneNumber.value.length === 0) {
    phoneNumber.value += digit;
    return;
  }
  phoneNumber.value += digit;
};

const onPhoneNumberkeyUp = async () => {
  await addDigit("");
};

// countries
const useCounty = useCountryStore();
let countries = ref(useCounty.getCountries);

let countryName = ref("");
const onCountryChange = () => {
  console.log(countryName.value);
  countries = useCounty.searchCountryByName(countryName.value);
};

let country = ref();

const onCountrySelect = (cy) => {
  country = cy;
  countryName.value = cy.name;
  phoneNumber.value.length === 0
    ? (phoneNumber.value = cy.dial_code + " ")
    : (phoneNumber.value = cy.dial_code + " " + phoneNumber.value);
};

const createChat = async () => {
  if (user.value != null) {
    let c = await useChat.createChat(user.value);
    if (c != null) {
      emits("update-chats", c);
    }
    user.value = null;
  }
};
</script>

<template>
  <v-menu
    :activator="activator"
    :close-on-back="false"
    :close-on-content-click="false"
  >
    <v-window v-model="step">
      <v-window-item :value="1">
        <v-list class="bg-white">
          <v-list-item>
            <v-list-item-title class="text-body font-weight-bold">
              New Chat
            </v-list-item-title>
          </v-list-item>
          <v-list-item>
            <v-text-field
              variant="outlined"
              placeholder="Search name or number"
              hide-details
              prepend-inner-icon="mdi-magnify"
            ></v-text-field>
          </v-list-item>
          <v-list-item
            @click="
              step++;
              isNewGroup = true;
            "
          >
            <template #prepend>
              <v-btn icon size="40" elevation="0" class="border mr-4">
                <v-icon>mdi-account-multiple-outline</v-icon>
              </v-btn>
            </template>
            <v-list-item-title>New group</v-list-item-title>
          </v-list-item>
          <v-list-item
            @click="
              step++;
              isNewPhoneNumber = true;
            "
          >
            <template #prepend>
              <v-btn icon size="40" elevation="0" class="border mr-4">
                <v-icon>mdi-phone-dial-outline</v-icon>
              </v-btn>
            </template>
            <v-list-item-title>New phone number</v-list-item-title>
          </v-list-item>
          <ChatItemComponent
            v-for="chat in chats"
            :key="chat.id"
            :chat="chat"
          />
        </v-list>
      </v-window-item>
      <v-window-item :value="2">
        <v-list v-if="isNewGroup">
          <v-list-item
            prepend-icon="mdi-chevron-left"
            @click="
              step--;
              isNewGroup = false;
            "
          >
          </v-list-item>
          <v-list-item>
            <v-text-field
              placeholder="Group name"
              prepend-inner-icon="mdi-account-group"
            ></v-text-field>
          </v-list-item>
          <v-list-item>
            <v-text-field
              placeholder="Members"
              prepend-inner-icon="mdi-account-multiple"
            ></v-text-field>
          </v-list-item>
        </v-list>
        <v-list v-if="isNewPhoneNumber" border>
          <v-list-item
            prepend-icon="mdi-chevron-left"
            @click="
              step--;
              isNewPhoneNumber = false;
            "
          >
          </v-list-item>
          <v-list-item>
            <v-text-field
              @keyup="onCountryChange"
              variant="outlined"
              placeholder="Country"
              v-model="countryName"
              id="country-name"
            >
              <template #prepend-inner v-if="country?.image">
                <v-img :src="country?.image" width="24" height="24"></v-img>
              </template>
            </v-text-field>
            <v-menu location="bottom" activator="#country-name">
              <v-list>
                <v-list-item
                  v-for="cy in countries"
                  :key="cy.id"
                  :prepend-avatar="cy.image"
                >
                  <!-- <v-img :src="cy.image" width="24"></v-img> -->
                  <v-list-item-title @click="onCountrySelect(cy)">
                    {{ cy.name }}
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-list-item>
          <v-list-item>
            <v-text-field
              @keyup="onPhoneNumberkeyUp"
              variant="outlined"
              placeholder="Phone number"
              prepend-inner-icon="mdi-phone"
              v-model="phoneNumber"
            ></v-text-field>
            <!-- <v-list-item-title>{{ phoneNumber }}</v-list-item-title> -->
          </v-list-item>
          <v-list-item>
            <v-card
              v-if="user != null"
              class="mx-auto"
              :title="user.username ?? user.phone"
              elevation="0"
            >
              <template #prepend>
                <v-avatar v-if="user?.image" size="32" class="border">
                  <v-img :src="user?.image != null" />
                </v-avatar>
                <v-avatar v-else size="32" class="border">
                  <v-icon>mdi-account-outline</v-icon>
                </v-avatar>
              </template>
              <v-card-actions>
                <v-btn
                  text="Chat"
                  block
                  class="border bg-green-lighten-2 text-white text-capitalize"
                  @click="createChat"
                ></v-btn>
              </v-card-actions>
            </v-card>
            <v-card v-else class="mx-auto" elevation="0">
              <v-card-text class="text-center text-caption" :opacity="30">
                Enter a phone number with country/region code to start a chat
              </v-card-text>
            </v-card>
          </v-list-item>
          <v-list-item>
            <v-btn width="30%" elevation="0" @click="addDigit('1')"> 1 </v-btn>
            <v-btn width="30%" elevation="0" @click="addDigit('2')"> 2 </v-btn>
            <v-btn width="30%" elevation="0" @click="addDigit('3')"> 3 </v-btn>
          </v-list-item>
          <v-list-item>
            <v-btn width="30%" elevation="0" @click="addDigit('4')"> 4 </v-btn>
            <v-btn width="30%" elevation="0" @click="addDigit('5')"> 5 </v-btn>
            <v-btn width="30%" elevation="0" @click="addDigit('6')"> 6 </v-btn>
          </v-list-item>
          <v-list-item>
            <v-btn width="30%" elevation="0" @click="addDigit('7')"> 7 </v-btn>
            <v-btn width="30%" elevation="0" @click="addDigit('8')"> 8 </v-btn>
            <v-btn width="30%" elevation="0" @click="addDigit('9')"> 9 </v-btn>
          </v-list-item>
          <v-list-item>
            <v-btn width="30%" elevation="0" @click="addDigit('+')"> + </v-btn>
            <v-btn width="30%" elevation="0" @click="addDigit('0')"> 0 </v-btn>
            <v-btn
              width="30%"
              elevation="0"
              @click="
                phoneNumber = phoneNumber.slice(0, phoneNumber.length - 1)
              "
            >
              <v-icon>mdi-backspace-outline</v-icon>
            </v-btn>
          </v-list-item>
        </v-list>
      </v-window-item>
    </v-window>
  </v-menu>
</template>
