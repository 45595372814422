<script setup>
import MainComponent from "@/components/MainComponent.vue";
import SideBarComponent from "@/components/SideBarComponent.vue";
import { ref, provide } from "vue";
const tab = ref("side-tab");
provide("tab", tab);
</script>

<template>
  <v-app>
    <SideBarComponent />
    <MainComponent />
  </v-app>
</template>
