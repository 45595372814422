import axios from "axios";
import { defineStore } from "pinia";
import { inject } from "vue";
import { useToast } from "vue-toast-notification";
import { useAuthStore } from "./auth";
// import "vue-toast-notification/dist/theme-sugar.css";

export const useUserStore = defineStore("user", {
  state: () => ({
    users: [],
    baseApiUrl: inject("baseApiUrl"),
  }),
  getters: {
    users() {
      return this.users;
    },
  },
  actions: {
    async getUsers() {
      try {
        let response = await axios.get(this.baseApiUrl + "/users", {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        });
        console.log(response);
        if (response.status == 200) {
          this.$state.users = response.data.users;
          useToast().success(response.data.message, {
            position: "top-right",
            duration: 3000,
            dismissible: true,
            pauseOnHover: true,
            type: "success",
          });
        }
      } catch (err) {
        console.log(err);
        useToast().error(err.response.data.message, {
          position: "top-right",
          duration: 3000,
        });
        await useAuthStore().logout();
      }
    },
    async getUser(id) {
      try {
        let response = await axios.get(this.baseApiUrl + "/users/" + id, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        });
        console.log(response);
        if (response.status == 200) {
          useToast().success(response.data.message, {
            position: "top-right",
            duration: 3000,
            dismissible: true,
            pauseOnHover: true,
            type: "success",
          });
          // await useAuthStore().logout();
          return response.data.user;
        }
        return null;
      } catch (err) {
        console.log(err);
        useToast().error(err.response.data.message, {
          position: "top-right",
          duration: 3000,
        });
        return null;
      }
    },
  },
});
