import { io } from "socket.io-client";
import { inject } from "vue";

let socket;

export const initSocket = () => {
  let baseApiUrl = inject("baseApiUrl");
  const originAPI = new URL(baseApiUrl).origin;
  socket = io(originAPI);
};

export const getSocket = () => {
  return socket;
};
