import { createRouter, createWebHistory } from "vue-router";
import HomeView from "@/views/HomeView.vue";
import { useAuthStore } from "@/stores/auth";
import { storeToRefs } from "pinia";

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  {
    path: "/login",
    name: "login",
    component: () => import("@/views/LoginView.vue"),
  },
  {
    path: "/register",
    name: "register",
    component: () => import("@/views/RegisterView.vue"),
  },
  {
    path: "/:pathMatch(.*)*",
    name: "not-found",
    component: () => import("@/views/NotFoundView.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach(async (to, from, next) => {
  // If the user is not logged in and tries to access a protected route, redirect to login useCookies
  const useAuth = storeToRefs(useAuthStore());
  const token = localStorage.getItem("token");
  console.log("token storage", localStorage.getItem("token"));
  console.log("isLoggedIn storage", useAuth.isLoggedIn?.value);
  if (
    to.name !== "login" &&
    to.name !== "register" &&
    !useAuth.isLoggedIn?.value &&
    !token
  )
    return next({ name: "login" });
  if (
    (to.name === "login" || to.name === "register") &&
    useAuth.isLoggedIn?.value &&
    token
  )
    return next({ name: "home" });
  return next();
});

export default router;
