import axios from "axios";
import { defineStore } from "pinia";
import { inject } from "vue";
import { useToast } from "vue-toast-notification";
// import "vue-toast-notification/dist/theme-sugar.css";

import { useAuthStore } from "./auth";

export const useChatStore = defineStore("chat", {
  state: () => ({
    chats: [],
    baseApiUrl: inject("baseApiUrl"),
  }),
  getters: {
    chats() {
      return this.chats;
    },
  },
  actions: {
    async getChats() {
      const toast = useToast();
      try {
        console.log("token", localStorage.getItem("token"));
        let response = await axios.get(this.baseApiUrl + "/chats", {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        });
        console.log(response);
        if (response.status == 200) {
          this.$state.chats = response.data.chats;
          console.log("chats", response.data.chats);
          return response.data.chats;
        }
        if (response.status == 401) await useAuthStore().logout();
        return [];
      } catch (err) {
        console.log(err);
        toast.error(err.response.data.message, {
          position: "top-right",
          duration: 3000,
        });
        if (err.response.status == 401) await useAuthStore().logout();
        return [];
      }
    },
    async createChat(chat) {
      const toast = useToast();
      try {
        let response = await axios.post(this.baseApiUrl + "/chats", chat, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        });
        console.log("created chat: ", response.status);
        console.log("created chat: ", response.data);
        if (response.status === 201) {
          this.$state.chats.push(response.data.chat);
          return response.data.chat;
        }

        if (response.status == 401) await useAuthStore().logout();
        return null;
      } catch (err) {
        console.log(err);
        toast.error(err.response.data.message, {
          position: "top-right",
          duration: 3000,
        });
        if (err.response.status == 401) await useAuthStore().logout();
        return null;
      }
    },
    async deleteChat(chat) {
      const toast = useToast();
      try {
        let response = await axios.delete(
          this.baseApiUrl + "/chats/" + chat._id,
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          }
        );
        if (response.status == 200) {
          this.chats = this.chats.filter((c) => c._id != chat._id);
        }
        if (response.status == 401) await useAuthStore().logout();
      } catch (err) {
        toast.error(err.response.data.message, {
          position: "top-right",
          duration: 3000,
        });
        if (err.response.status == 401) await useAuthStore().logout();
      }
    },
    async updateChat(chat) {
      const toast = useToast();
      try {
        let response = await axios.put(this.baseApiUrl + "/chats/" + chat._id, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
          body: { chat },
        });
        if (response.status == 200) {
          let index = this.chats.findIndex((c) => c._id == chat._id);
          this.chats[index] = response.data.chat;
          toast.success(response.data.message, {
            position: "top-right",
            duration: 3000,
            dismissible: true,
            pauseOnHover: true,
            type: "success",
          });
        }
      } catch (err) {
        toast.error(err.response.data.message, {
          position: "top-right",
          duration: 3000,
        });
        await useAuthStore().logout();
      }
    },
    async getChat(chatId) {
      const toast = useToast();
      try {
        let response = await axios.get(this.baseApiUrl + "/chats/" + chatId, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        });
        if (response.status == 200) {
          return response.data.chat;
        }
      } catch (err) {
        toast.error(err.response.data.message, {
          position: "top-right",
          duration: 3000,
        });
        await useAuthStore().logout();
      }
    },
  },
});
