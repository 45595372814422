<script setup>
import NavigationDrawerComponent from "@/components/NavigationDrawerComponent.vue";
import SideArchivedChatComponent from "@/components/SideArchivedChatComponent.vue";
import SideChatComponent from "@/components/SideChatComponent.vue";
import SideCallComponent from "@/components/SideCallComponent.vue";
import { inject, defineEmits } from "vue";
const tab = inject("tab");
let emits = defineEmits(["open-chat"]);
const openChat = (chat) => {
  emits("open-chat", chat);
};
</script>

<template>
  <NavigationDrawerComponent :visible="true" width="320">
    <v-tabs-window v-model="tab">
      <v-tabs-window-item>
        <SideChatComponent @open-chat="openChat" />
      </v-tabs-window-item>
      <v-tabs-window-item>
        <SideCallComponent />
      </v-tabs-window-item>
      <v-tabs-window-item>
        <SideArchivedChatComponent />
      </v-tabs-window-item>
    </v-tabs-window>
  </NavigationDrawerComponent>
</template>
