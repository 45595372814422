import { createApp, markRaw } from "vue";
import App from "./App.vue";
import router from "./router";
import { createPinia } from "pinia";

import ToastPlugin from "vue-toast-notification";
// Import one of the available themes
//import 'vue-toast-notification/dist/theme-default.css';
import "vue-toast-notification/dist/theme-bootstrap.css";

// Vuetify
import "@mdi/font/css/materialdesignicons.css";
import "vuetify/styles";
import { createVuetify } from "vuetify";
import * as components from "vuetify/components";
import * as directives from "vuetify/directives";

const vuetify = createVuetify({
  components,
  directives,
});

const pinia = createPinia();

pinia.use(({ store }) => {
  store.$router = markRaw(router);
});

createApp(App)
  .use(router)
  .use(vuetify)
  .use(pinia)
  .use(ToastPlugin)
  .provide("baseApiUrl", "https://chatappapi.devnest.work/api/v1")
  .mount("#app");
