<script setup>
import NavigationDrawerComponent from "@/components/NavigationDrawerComponent.vue";
import { ref, inject } from "vue";
const tab = inject("tab");
let rail = ref(true);
const toggleVisible = () => (rail.value = !rail.value);
</script>

<template>
  <NavigationDrawerComponent color="green-lighten-5" :rail="rail">
    <v-tabs
      v-model="tab"
      direction="vertical"
      class="d-flex flex-column justify-space-between h-100"
    >
      <v-btn
        class=""
        icon="mdi-menu"
        variant="text"
        @click="toggleVisible"
      ></v-btn>
      <v-tab text="Chats">
        <template #prepend>
          <v-icon size="24" :class="{ 'mr-5': rail, 'ml-1': true }"
            >mdi-chat-outline</v-icon
          >
        </template>
      </v-tab>
      <v-tab text="Calls">
        <template #prepend>
          <v-icon size="24" :class="{ 'mr-5': rail, 'ml-1': true }"
            >mdi-phone-outline</v-icon
          >
        </template>
      </v-tab>
      <v-spacer />
      <v-tab text="Archived chats">
        <template #prepend>
          <v-icon size="24" :class="{ 'mr-5': rail, 'ml-1': true }"
            >mdi-archive-outline</v-icon
          >
        </template>
      </v-tab>
      <v-divider />
      <v-menu>
        <template v-slot:activator="{ props }">
          <v-card @click.prevent="" class="bg-transparent" elevation="0">
            <v-tab text="Settings" :readonly="true" v-bind="props">
              <template #prepend>
                <v-icon size="24" :class="{ 'mr-5': rail, 'ml-1': true }"
                  >mdi-cog-outline</v-icon
                >
              </template>
            </v-tab>
          </v-card>
        </template>
      </v-menu>
      <v-menu>
        <template v-slot:activator="{ props }">
          <v-card @click.prevent="" class="bg-transparent" elevation="0">
            <v-tab text="Profile" :readonly="true" v-bind="props">
              <template #prepend>
                <v-icon size="24" :class="{ 'mr-5': rail, 'ml-1': true }"
                  >mdi-account-circle-outline</v-icon
                >
              </template>
            </v-tab>
          </v-card>
        </template>
      </v-menu>
      <!-- <v-list>
        <v-list-item prepend-icon="mdi-cog-outline" @click="toggleVisible">
          <v-list-item-title>Settings</v-list-item-title>
        </v-list-item>
        <v-list-item
          prepend-icon="mdi-account-circle-outline"
          @click="toggleVisible"
        >
          <v-list-item-title>Profile</v-list-item-title>
        </v-list-item>
      </v-list> -->
    </v-tabs>

    <!-- 
        <div class="d-flex flex-column justify-space-between h-100">
      <v-list>
        <v-list-item prepend-icon="mdi-menu" @click="toggleVisible">
        </v-list-item>
        <v-list-item prepend-icon="mdi-chat-outline" @click="toggleVisible">
          <v-list-item-title>Chats</v-list-item-title>
        </v-list-item>
        <v-list-item prepend-icon="mdi-phone-outline" @click="toggleVisible">
          <v-list-item-title>Calls</v-list-item-title>
        </v-list-item>
      </v-list>
      <v-list>
        <v-list-item prepend-icon="mdi-archive-outline" @click="toggleVisible">
          <v-list-item-title>Archived chats</v-list-item-title>
        </v-list-item>
        <v-divider />
        <v-list-item prepend-icon="mdi-cog-outline" @click="toggleVisible">
          <v-list-item-title>Settings</v-list-item-title>
        </v-list-item>
        <v-list-item
          prepend-icon="mdi-account-circle-outline"
          @click="toggleVisible"
        >
          <v-list-item-title>Profile</v-list-item-title>
        </v-list-item>
      </v-list> 
    </div>-->
  </NavigationDrawerComponent>
</template>
