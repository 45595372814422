import axios from "axios";
import { defineStore } from "pinia";
import { inject } from "vue";
import { useToast } from "vue-toast-notification";
import { useAuthStore } from "./auth";
import { getSocket } from "@/mixins/socket";
// import "vue-toast-notification/dist/theme-sugar.css";

export const useMessageStore = defineStore("message", {
  state: () => ({
    messages: [],
    baseApiUrl: inject("baseApiUrl"),
  }),
  getters: {
    messages() {
      return this.messages;
    },
  },
  actions: {
    async getMessages(chatId) {
      const toast = useToast();
      try {
        console.log("token", localStorage.getItem("token"));
        let response = await axios.get(
          this.baseApiUrl + "/chats/" + chatId + "/messages",
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          }
        );
        console.log(response);
        if (response.status == 200) {
          console.log("messages", response.data.messages);
          this.$state.messages = response.data.messages;
          return response.data.messages;
        }
        return [];
      } catch (err) {
        console.log(err);
        toast.error(err.response.data.message, {
          position: "top-right",
          duration: 3000,
        });
        await useAuthStore().logout();
        return [];
      }
    },
    async createMessage(chatId, message) {
      const toast = useToast();
      try {
        let response = await axios.post(
          this.baseApiUrl + "/chats/" + chatId + "/messages",
          { message },
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          }
        );
        console.log("created message: ", response.data);
        console.log("created message: ", response.status);
        if (response.status == 201) {
          // this.messages.push(response.data._message);
          const socket = getSocket();
          socket.emit("send_message", response.data._message);
          return response.data._message;
        }
        return null;
      } catch (err) {
        console.log(err);
        toast.error(err.response.data.message, {
          position: "top-right",
          duration: 3000,
        });
        await useAuthStore().logout();
        return null;
      }
    },
    async deleteMessage(message) {
      const toast = useToast();
      try {
        let response = await axios.delete(
          this.baseApiUrl + "/messages/" + message._id,
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          }
        );
        if (response.status == 200) {
          this.messages = this.messages.filter((c) => c._id != message._id);
          toast.success(response.data.message, {
            position: "top-right",
            duration: 3000,
            dismissible: true,
            pauseOnHover: true,
            type: "success",
          });
        }
      } catch (err) {
        toast.error(err.response.data.message, {
          position: "top-right",
          duration: 3000,
        });
        await useAuthStore().logout();
      }
    },
    async updateMessage(message) {
      const toast = useToast();
      try {
        let response = await axios.put(
          this.baseApiUrl + "/messages/" + message._id,
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
            body: { message },
          }
        );
        if (response.status == 200) {
          let index = this.messages.findIndex((c) => c._id == message._id);
          this.messages[index] = response.data.message;
          toast.success(response.data.message, {
            position: "top-right",
            duration: 3000,
            dismissible: true,
            pauseOnHover: true,
            type: "success",
          });
        }
      } catch (err) {
        toast.error(err.response.data.message, {
          position: "top-right",
          duration: 3000,
        });
        await useAuthStore().logout();
      }
    },
    async getMessage(messageId) {
      const toast = useToast();
      try {
        let response = await axios.get(
          this.baseApiUrl + "/messages/" + messageId,
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          }
        );
        if (response.status == 200) {
          return response.data.message;
        }
      } catch (err) {
        toast.error(err.response.data.message, {
          position: "top-right",
          duration: 3000,
        });
        await useAuthStore().logout();
      }
    },
  },
});
