import { getSocket } from "@/mixins/socket";
import axios from "axios";
import { defineStore } from "pinia";
import { inject } from "vue";
import { useToast } from "vue-toast-notification";
// import "vue-toast-notification/dist/theme-sugar.css";

export const useAuthStore = defineStore("auth", {
  state: () => ({
    user: JSON.parse(localStorage.getItem("user")),
    isLoggedIn: JSON.parse(localStorage.getItem("isLoggedIn")),
    baseApiUrl: inject("baseApiUrl"),
  }),
  getters: {
    isLoggedIn() {
      return this.isLoggedIn;
    },
    user() {
      return JSON.parse(localStorage.getItem("user"));
    },
  },
  actions: {
    async login(user) {
      const toast = useToast();
      try {
        let response = await axios.post(this.baseApiUrl + "/auth/login", user);
        console.log(response);
        if (response.status == 200) {
          localStorage.setItem("token", response.data.token);
          // this.$state.user = response.data.user;
          // this.$state.isLoggedIn = true;
          // this.$router.push({ name: "home" });

          localStorage.setItem("user", JSON.stringify(response.data.user));
          localStorage.setItem("isLoggedIn", JSON.stringify(true));

          toast.success(response.data.message, {
            position: "top-right",
            duration: 3000,
            dismissible: true,
            pauseOnHover: true,
            type: "success",
          });

          getSocket().connect();

          return { user: response.data.user, isLoggedIn: true };
        }
        return { user: null, isLoggedIn: false };
      } catch (err) {
        toast.error(err.response.data.message, {
          position: "top-right",
          duration: 3000,
        });
        return { user: null, isLoggedIn: false };
      }
    },
    async register(user) {
      const toast = useToast();
      try {
        let response = await axios.post(
          this.baseApiUrl + "/auth/register",
          user
        );
        console.log(response);
        if (response.status == 201) {
          toast.success(response.data.message, {
            position: "top-right",
            duration: 3000,
            dismissible: true,
            pauseOnHover: true,
            type: "success",
          });
          this.$router.push({ name: "login" });
        }
      } catch (err) {
        toast.error(err.response.data.message, {
          position: "top-right",
          duration: 3000,
        });
      }
    },
    async logout() {
      try {
        let res = await axios.post(this.baseApiUrl + "/auth/logout", {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        });
        console.log(res);
        localStorage.removeItem("user");
        localStorage.removeItem("isLoggedIn");
        localStorage.removeItem("token");
        this.$router.push({ name: "login" });
      } catch (err) {
        console.log(err);
        localStorage.removeItem("user");
        localStorage.removeItem("isLoggedIn");
        localStorage.removeItem("token");
        this.$router.push({ name: "login" });
      }
    },
  },
});
