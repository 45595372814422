<script setup>
// import NavigationDrawerComponent from "@/components/NavigationDrawerComponent.vue";
import ChatItemComponent from "@/components/chats/ChatItemComponent.vue";
import NewChatMenuComponent from "@/components/chats/NewChatMenuComponent.vue";
import FilterChatMenuComponent from "@/components/chats/FilterChatMenuComponent.vue";
import { useChatStore } from "@/stores/chat";
import { ref, defineEmits } from "vue";
let useChat = useChatStore();
let chats = ref([]);
chats = await useChat.getChats();
let emits = defineEmits(["open-chat"]);
const showMessages = (chat) => {
  emits("open-chat", chat);
};
const updateChats = async (c) => {
  console.log(c);
  if (c) chats.value.push(c);
};
</script>

<template>
  <v-list>
    <v-list-item>
      <v-list-item-title class="text-h5 font-weight-bold">
        Chats
      </v-list-item-title>
      <template #append>
        <div class="d-flex ga-3">
          <v-btn variant="text" icon="mdi-pen-plus" id="new-chat-btn"></v-btn>
          <NewChatMenuComponent
            :activator="'#new-chat-btn'"
            @update-chats="updateChats"
            width="350"
          />
          <v-btn
            variant="text"
            icon="mdi-filter-variant"
            id="filter-chat-btn"
          ></v-btn>
          <FilterChatMenuComponent
            :activator="'#filter-chat-btn'"
            width="200"
          />
        </div>
      </template>
    </v-list-item>
    <v-list-item>
      <v-text-field
        variant="outlined"
        placeholder="Search"
        hide-details
        prepend-inner-icon="mdi-magnify"
      ></v-text-field>
    </v-list-item>
    <ChatItemComponent
      v-for="chat in chats"
      :key="chat.id"
      :chat="chat"
      @click="showMessages(chat)"
    />
  </v-list>
</template>
